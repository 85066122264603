import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import Pagination from "react-pagination-js";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { CiAlignRight } from "react-icons/ci";
import { MdDataObject } from "react-icons/md";
import ModalOKR from "./ModalOKR";
import { useDispatch, useSelector } from "react-redux";
import AlignmentOKR from "./AlignmentOKR";
import { FaUsers } from "react-icons/fa6";
import { FaUsersSlash } from "react-icons/fa6";
import { LuCircleDotDashed } from "react-icons/lu";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { MdOutlineSignalCellularAlt } from "react-icons/md";
import { sessions } from "../../actions/session";
import Select from "react-select";
import { Menu } from "@headlessui/react";
import { CiFilter } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import OKRindex from ".";

const OKRs = ({
  layout = true, ownerID,sessionId,getcounts
}) => {


  // const { id } = useParams();
  
  // const [LoadingState, setLoadingState] = useState(false);
  // const getsessionId = localStorage.getItem("sessionID")
  // const dispatch = useDispatch();
  // const user = useSelector((state) => state.user);
  // const sessionss = useSelector((state) => state.session);
  // const SessionFilter = sessionss?.data ? { label: sessionss?.data, value: id } : ""
  // const [data, setdata] = useState([]);

  // const [filter, setfilter] = useState({
  //   page: 1,
  //   count: 20,
  //   search: "",
  //   ownerId: "",
  //   sessionId: sessionId,
  // });
  // const [SessionIdFilter, setSessionIdFilter] = useState(SessionFilter)

  // const [loading, setloading] = useState(false);
  // const [total, settotal] = useState(0);
  // const [detail, setdetail] = useState();
  // const navigate = useHistory();
  // // Create OKRs
  // const [session, setsessions] = useState();
  // const [owners, setowners] = useState([]);
  // const [multiOptions, setmultiOptions] = useState([]);
  // const [form, setform] = useState({ session: "", title: "", ownerIds: [] });
  // // Add Key Result
  // const [keyResult, setkeyResult] = useState({
  //   title: "",
  //   unit: "",
  //   targetNumber: "",
  //   initialNumber: "",
  //   description: "",
  //   ownerIds: [],
  //   deadline: "no custom deadline",
  //   softDeadline: "",
  //   hardDeadline: "",
  // });
  // const [editor, seteditor] = useState({ keyResult: "", noteKeyResult: "" });
  // const [updateKeyResult, setupdateKeyResult] = useState({
  //   newValue: "",
  //   confidenceLevel: "high",
  //   note: "",
  //   dateOfUpdate: new Date(),
  //   okrId: "",
  //   goal: "",
  //   currentValue: "",
  //   keyResultId: "",
  // });
  // const [chlildObjective, setchlidObjective] = useState();
  // const [keyResultData, setkeyResultData] = useState();
  // const [parentKeyResult, setparentKeyResult] = useState();
  const [dataResources, setResources] = useState([]);

  // useEffect(() => {
  //   if (ownerID && ownerID?.length > 0) {
  //     let str = ownerID.map(itm => itm.value).toString()
  //     setTimeout(() => {
  //       getData({ ownerId: str })
  //     }, 100);
  //   } else {
  //     setfilter({ ...filter, ownerId: "" })
  //   }

  // }, [])



  // useEffect(() => {
  //   getSessions();
  //   getOwners();
  //   if (id) {
  //     getSessionDetail();
  //   }
  //   if (sessionss?.data) {
  //     localStorage.setItem("sessionNAme", sessionss?.data)
  //   }
  // }, [id]);

  // useEffect(() => {
  //   if (id) {
  //     let el = document.getElementById("pills-objective-tab")
  //     if (el) { el.click() }
  //     if (user?.role == "employee") {
  //       getData({ ownerId: user?.id || user?._id });
  //     } else {
  //       getData({ addedBy: user?.id || user?._id, ownerId: '' });
  //       setfilter({ ...filter, addedBy: user?.id || user?._id, ownerId: [] })
  //     }
  //   }
  // }, [id, user]);

  // useEffect(() => {
  //   if (!id && !ownerID) {
  //     if (user?.role == "employee") {
  //       getData({ ownerId: user?.id || user?._id });
  //     } else {
  //       getData({ addedBy: user?.id || user?._id, ownerId: "" });
  //       setfilter({ ...filter, addedBy: user?.id || user?._id, ownerId: '' })
  //     }
  //   }
  // }, [user]);

  // const arrTostr = (arr) => {
  //   let array = arr;
  //   let string = ""
  //   if (arr == "") {
  //     return
  //   } else {
  //     if (arr?.length > 0 && typeof (arr) == "string") {
  //       string = array
  //     } else {
  //       if (array?.length > 0) {
  //         array = array.map(item => item?.value)
  //         string = array.toString()
  //       }
  //     }

  //   }
  //   return string;

  // }
  // const getData = (p = {}, load = true) => {
  //   let filt = { ...filter, ...p, addedBy:user?.id||user?._id,sessionId: sessionId || getsessionId };
  //   let filterss={sessionId:sessionId}
  //   loader(load);
  //   setLoadingState(true);
  //   ApiClient.get(`okrs`, filt).then((res) => {
  //     setLoadingState(false)
  //     if (res.success) {
  //       setdata([...res?.data]);
  //       settotal(res?.total);
  //       if (detail?.id || detail?._id) {
  //         setdetail(
  //           res?.data?.find(
  //             (item) => item?._id == detail?.id || item?._id == detail?._id
  //           )
  //         );
  //       }
  //     }
  //     loader(false);
  //   });
  // };

  // const getSessionDetail = () => {
  //   ApiClient.get(`session?id=${id || getsessionId}`).then((res) => {
  //     if (res.data?.id) {
  //       setform({
  //         ...form,
  //         session: { value: res?.data?.id, label: res?.data?.title },
  //       });
  //     }
  //   });
  // };

  // const getKeyResults = (id = "") => {
  //   loader(true);
  //   ApiClient.get(
  //     `okrs?okrId=${id ? id : detail?.id ? detail?.id : detail?._id}&addedBy=${user?.role == "employee" ? user?.addedBy : user?.id
  //     }`
  //   ).then((res) => {
  //     if (res.success) {
  //       setkeyResultData(res?.data);
  //       setparentKeyResult(
  //         res?.data?.map((item) => {
  //           return { value: item?._id, label: item?.title };
  //         })
  //       );
  //     }
  //     loader(false);
  //   });
  // };

  // const clearFields = () => {
  //   setform({ ...form, title: "", ownerIds: "" });
  // };

  // const getOwners = () => {
  //   ApiClient.get(`users/list?status=active`).then((res) => {
  //     if (res.success) {
  //       setdata(res.data)
  //       ApiClient.get(`teams`).then((res1) => {
  //         let arr2 = res1?.data?.map((item) => {
  //           return { value: item?._id || item?.id, label: item?.fullName || item?.firstName };
  //         });
  //         let arr = res?.data?.map((item) => {
  //           return { value: item?.id || item?._id, label: item?.fullName || item?.firstName };
  //         });
  //         let Multiarr2 = res1?.data?.map((item) => {
  //           return { id: item?._id || item?.id, name: item?.fullName || item?.firstName };
  //         });
  //         let Multiarr = res?.data?.map((item) => {
  //           return { id: item?.id || item?._id, name: item?.fullName || item?.firstName };
  //         });

  //         setmultiOptions([...Multiarr, ...Multiarr2]);
  //         setowners([...arr, ...arr2]);
  //       });
  //     }
  //   });
  // };

  // const pageChange = (e) => {
  //   setfilter({ ...filter, page: e });
  //   getData({ page: e });
  // };

  // const handleAction = (type, id) => {
  //   if (type == "Edit") {
  //     ApiClient.get(`okr?id=${id}`).then((res) => {
  //       if (res.data?.id) {
  //         setdetail(res?.data);
  //         setform({
  //           id: res?.data?.id,
  //           title: res?.data?.title,
  //           session: {
  //             value: res?.data?.sessionTitle?.id,
  //             label: res?.data?.sessionTitle?.title,
  //           },
  //           ownerIds: res?.data?.ownerDetails?.map((itm) => {
  //             return { value: itm?.id, label: itm?.fullName };
  //           }),
  //         });
  //         document.getElementById("CreateOKRModalOpen").click();
  //       }
  //     });
  //   } else {
  //     if (window.confirm("Do you really want to delete this okr")) {
  //       ApiClient.delete(`delete?id=${id}&model=okr`).then((res) => {
  //         if (res.success) {
  //           toast.success(res?.message);
  //           getData();
  //           document.getElementById("CloseFullSideScreenModal").click();
  //         }
  //       });
  //     }
  //   }
  // };

  // const detailModal = (id, value = true) => {
  //   getKeyResults(id);
  //   ApiClient.get(`okr?id=${id}`).then((res) => {
  //     if (res?.data?.id) {
  //       setdetail(res?.data);
  //       setkeyResult({
  //         ...keyResult,
  //         ownerIds: res?.data?.ownerDetails?.map((itm) => {
  //           return { value: itm?.id, label: itm?.fullName };
  //         }),
  //       });
  //       setupdateKeyResult({ ...updateKeyResult, okrId: res?.data?.id||res?.data?._id });
  //       if (value) {
  //         document.getElementById("OpenFullSideScreenModal").click();
  //       }
  //     }
  //   });
  // };

  // const findOKRProgress = (data, p = "okr from where?") => {
  //   let value = 0;
  //   let length = data?.length;
  //   if (length > 0) {
  //     data &&
  //       data?.map((item) => {
  //         value +=
  //           ((Number(item?.newValue) - Number(item?.initialNumber)) /
  //             (Number(item?.targetNumber) - Number(item?.initialNumber))) *
  //           100;
  //       });
  //     return `${Math.abs(Number(value) / Number(length)).toFixed(0)}%`;
  //   } else {
  //     return "0%";
  //   }
  // };

  // const clearOKRForm = () => {
  //   setdetail("");
  //   setchlidObjective("");
  // };

  const resources =
    dataResources &&
    dataResources.map((item) => {
      return { id: item?._id, title: item?.title };
    });


  // const handleFilter = () => {
  //   if (filter?.ownerId && filter?.ownerId?.length > 0) {
  //     let arr = filter?.ownerId || ownerID
  //     getData({ page: 1, ownerId: arr.map((item) => item?.value).toString() });
  //   } else {
  //     getData({ page: 1, ownerId: "" })

  //   }
  // }

  // const handlechangesessions = (e) => {
  //   if (e) {
  //     dispatch(sessions(`${e.label}`));
  //     setSessionIdFilter(e)
  //     setfilter({ ...filter, sessionId: e });
  //     navigate.push(`/sessions/okrs/${e.value}`);
  //     localStorage.setItem("sessionID", e.value)
  //   }
  // };
  return (
    <>
      {layout ? <Layout resources={resources}>
       <OKRindex layout={layout}/>
      </Layout> :
        <OKRindex layout={layout} ownerID={ownerID} sessionId={sessionId} getcounts={getcounts}/>}
    </>
  );
};

export default OKRs;
